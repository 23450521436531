import {
  ServiceInfoDto,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';
import { LocationMapper } from '../../mappers/location.mapper';

export interface SchedulingLocationViewModel {
  currentLocation?: string;
  locationOptions?: LocationDropdownOption[];
}

export interface LocationDropdownOption {
  id: string;
  value: string;
}

export const schedulingLocationViewModelFactory = ({
  serviceInfoDto,
  selectedLocation,
}: {
  serviceInfoDto: ServiceInfoDto;
  selectedLocation?: string;
}): SchedulingLocationViewModel => {
  let locationOptions;
  if (Number(serviceInfoDto.locations?.length) > 1) {
    const locationMapper = new LocationMapper('');
    locationOptions = serviceInfoDto.locations
      ?.filter(
        (serviceLocation) =>
          serviceLocation.type === ServiceLocationType.OWNER_BUSINESS,
      )
      .map((serviceLocation) => ({
        id: serviceLocation?.businessLocation?.id,
        value: locationMapper.text({
          serviceLocation,
          useBusinessName: true,
        }),
      }));
  }

  let currentLocation;
  if (selectedLocation) {
    currentLocation = selectedLocation;
  } else {
    const defaultLocationId = serviceInfoDto.locations?.find(
      (location) => location.businessLocation?.default,
    )?.businessLocation?.id;

    currentLocation =
      defaultLocationId || serviceInfoDto.locations?.[0].businessLocation?.id;
  }
  return {
    currentLocation,
    locationOptions,
  };
};
