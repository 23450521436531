import { HeaderViewModel } from '../header-view-model/headerViewModel';
import { ImageDto } from '@wix/bookings-uou-types';

export class HeaderViewModelBuilder {
  viewModel: HeaderViewModel = {
    title: '',
    imageUri: '506418dbb019414f951a61670f3255a8.jpg',
    isBookable: true,
  };

  withBookOnlineAllowed(isBookable: boolean) {
    this.viewModel.isBookable = isBookable;
    return this;
  }

  withTitle(title: string) {
    this.viewModel.title = title;
    return this;
  }

  withImageUri(imageUri: string) {
    this.viewModel.imageUri = imageUri;
    return this;
  }

  withImage(image: ImageDto) {
    this.viewModel.image = image;
    return this;
  }

  build() {
    return this.viewModel;
  }
}
