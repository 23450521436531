import { OfferedAsType } from '@wix/bookings-uou-types';

export const isServiceOfferedAsPricingPlan = (
  serviceDto,
  isPricingPlanInstalled,
) =>
  getOfferedAs(serviceDto, isPricingPlanInstalled).indexOf(
    OfferedAsType.PRICING_PLAN,
  ) >= 0;

export const isServiceConnectedToPricingPlan = (serviceDto) =>
  !!(
    serviceDto.pricingPlanInfo &&
    serviceDto.pricingPlanInfo.pricingPlans &&
    serviceDto.pricingPlanInfo.pricingPlans.length
  );

export const getOfferedAs = (serviceDto, isPricingPlanInstalled) => {
  if (
    serviceDto.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0 &&
    serviceDto.offeredAs.indexOf(OfferedAsType.PRICING_PLAN) >= 0
  ) {
    if (
      serviceDto.pricingPlanInfo.pricingPlans.length === 0 ||
      !isPricingPlanInstalled
    ) {
      return [OfferedAsType.ONE_TIME];
    }
  }
  return serviceDto.offeredAs;
};
