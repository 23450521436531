import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  BOOKINGS_CHECKOUT_SECTION_ID,
  BOOKINGS_APP_DEF_ID,
} from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/bookings.const';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { FailReasons } from '../constants';
import {
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '../../../mappers/pricing-plan-helpers';
import { WidgetConfig } from '../../../types/shared-types';
import { biDefaults } from '../bi/consts';

export const handleNavigation = async ({
  config,
  wixCodeApi,
  isPreview,
  wixSdkAdapter,
  onNavigationFailed,
  locationId,
  isUseSdkAdapterNavigationInServicePageEnabled,
}: {
  config: WidgetConfig;
  wixCodeApi: IWixAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  isPreview: boolean;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
  locationId?: string;
  isUseSdkAdapterNavigationInServicePageEnabled: boolean;
}) => {
  if (
    isPreview ||
    wixSdkAdapter.isDemoMode() ||
    wixSdkAdapter.isTemplateMode() ||
    (config.isFeatureEnabled &&
      (await isPricingPlanNavigateEnabled(wixSdkAdapter, config.serviceDto)))
  ) {
    if (isUseSdkAdapterNavigationInServicePageEnabled) {
      const optionalParams = {
        referral: biDefaults.service_page_referral_info,
        ...(locationId ? { location: locationId } : {}),
      };
      return wixSdkAdapter.navigateToBookingsCalendarPage(
        config.serviceDto.urlName,
        optionalParams,
      );
    } else {
      const bookingsRelativeUrl = await getBookingsRelativeUrl(
        config,
        wixCodeApi,
      );
      const queryLocation = locationId ? `?location=${locationId}` : '';
      wixCodeApi.location.to &&
        wixCodeApi.location.to(`${bookingsRelativeUrl}/book${queryLocation}`);
    }
  } else {
    if (!config.isFeatureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!(await wixSdkAdapter.isPricingPlanInstalled())) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan(config.serviceDto)) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (wixSdkAdapter, serviceDto) => {
  const isPricingPlanInstalled = await wixSdkAdapter.isPricingPlanInstalled();
  return (
    !isServiceOfferedAsPricingPlan(serviceDto, isPricingPlanInstalled) ||
    (isServiceConnectedToPricingPlan(serviceDto) && isPricingPlanInstalled)
  );
};

const getBookingsRelativeUrl = async (config, wixCodeApi): Promise<string> => {
  const serviceSlug = config.serviceDto.urlName;
  const fullBookingsUrl =
    (
      await wixCodeApi.site.getSectionUrl({
        sectionId: BOOKINGS_CHECKOUT_SECTION_ID,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      })
    ).url + `/${serviceSlug}`;
  const baseUrl = wixCodeApi.location.baseUrl;
  if (baseUrl) {
    const urlParts = fullBookingsUrl.split(baseUrl);
    return urlParts.length > 1 ? urlParts[1] : fullBookingsUrl;
  }
  return fullBookingsUrl;
};
